<template>
  <default-layout :showHeader="false">
    <div class="h-full bg-center bg-no-repeat bg-cover text-center">
      <div class="p-4 px-1 pb-0">
        <div class="my-3">
          <img
            src="@/assets/images/app-logo.png"
            alt="app-logo"
            class="inline"
            width="192"
            height="42"
          />
        </div>
        <div class="text-lg">
          <p class="mb-0">Nền tảng công nghệ giúp Người lao động</p>
          <p class="text-primary mb-0 font-bold text-xl">
            Ứng lương tự động chỉ với 33k
          </p>
        </div>
      </div>
      <div class="p-4">
        <div class="my-3 inline-block">
          <img
            src="@/assets/images/intro.svg"
            alt="intro-img"
            class="mx-auto"
            width="242"
            height="198"
          />
        </div>
      </div>
      <div>
        <div class="pb-7 px-7 text-center">
          <p class="text-lg">
            Bạn đã có tài khoản trên
            <span class="text-primary font-bold">Interloan</span> chưa?
          </p>
          <div class="mt-2 flex flex-col justify-evenly items-end">
            <base-button
              class="h-10 w-full border-16 bg-tertiary text-white"
              @click="handleClickNo"
            >
              Chưa, Tham gia ngay!
            </base-button>
            <base-button
              class="h-10 text-primary mt-4 w-full border-16 bg-transparent bg-none border-1 border-primary shadow-none"
              @click="handleClickYes"
            >
              Đăng nhập
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </default-layout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import ConstRouter from '@/constants/constRouter';
import BaseButton from '@/components/Base/BaseButton.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';

export default defineComponent({
  name: 'IntroSlide',
  components: { BaseButton, DefaultLayout },
  setup() {
    const router = useRouter();
    const handleClickNo = () => {
      router.push({
        name: ConstRouter.BORROWER_MONEY.name,
      });
    };
    const handleClickYes = () => {
      router.push({
        name: ConstRouter.LOGIN.name,
      });
    };
    return {
      handleClickNo,
      handleClickYes,
    };
  },
});
</script>
