
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import ConstRouter from '@/constants/constRouter';
import BaseButton from '@/components/Base/BaseButton.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';

export default defineComponent({
  name: 'IntroSlide',
  components: { BaseButton, DefaultLayout },
  setup() {
    const router = useRouter();
    const handleClickNo = () => {
      router.push({
        name: ConstRouter.BORROWER_MONEY.name,
      });
    };
    const handleClickYes = () => {
      router.push({
        name: ConstRouter.LOGIN.name,
      });
    };
    return {
      handleClickNo,
      handleClickYes,
    };
  },
});
